import React from "react";
import Section from "./Section";

class Chapter extends React.Component {
    render() {
        let { chapter, chapterIndex, sectionIndex, bulletIndex } = this.props;
        let chapterTitle = undefined;
        if (chapter !== undefined && chapter.title !== undefined) {
            chapterTitle = chapter.title.replace(/#/g, '');
        }
        return (
            <div className="chapterContent" key={'div'+chapterTitle}>
                <h1 key={'h1'+chapterTitle}>{chapterTitle}</h1>
                {/*
                 Here's where we need to parse out section indexes 
                */}
                <Section 
                    chapterIndex={chapterIndex}
                    sectionIndex={sectionIndex}
                    bulletIndex={bulletIndex}
                    section={chapter.sections[sectionIndex]}
                    />

                {/*chapter.sections.map((section, s_index) => {
                    return (
                        <Section 
                            chapterIndex={chapterIndex}
                            sectionIndex={sectionIndex} 
                            bulletIndex={bulletIndex} 
                            section={section} />
                    )
                })*/}
            </div>
        )
    }
}
export default Chapter;