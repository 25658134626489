// npm install markdown
import React, { Component } from 'react';

const md_parser = (data) => {
    let courseContent = [];
    let lines = data.split('\n');
    let chapterContent = {};
    let sectionContent = {};
    let slides = [];
    let pictures = [];
    for (let line=0;line < lines.length; line++) {
        let thisLine = lines[line];
        if ( thisLine.slice(0, 12) === ('# **Chapter ') ) {
            if (Object.keys(chapterContent).length > 0) {
                sectionContent.slides = formatSlides(slides);
                sectionContent.pictures = pictures;
                chapterContent.sections.push(sectionContent);
                courseContent.push(chapterContent);
                sectionContent = {};
                slides = [];
                pictures = [];
            }
            chapterContent = {
                "title":thisLine.replace(/\**/g, ''), 
                "sections":[],
            };
        } else if ( thisLine.slice(0,2) == '**') {
            // Append a section.
            if (Object.keys(sectionContent).length > 0) {
                // Push the previous section to the array. 
                sectionContent.slides = formatSlides(slides);
                sectionContent.pictures = pictures;
                chapterContent.sections.push(sectionContent);
            }
            sectionContent =  {
                "title":thisLine, //.replace(/\**/g, '<h2>'),
                "slides": [],
                "pictures": []
            }
            slides = [];
            pictures = [];
         } else if ( thisLine.includes('<picture>') ) {
            // Append picture with URL found between <picture>{URL}</picture>
            pictures.push(thisLine) //.replace(/<picture>/g, ''));
         } else {
            slides.push(thisLine);
            // Append a slide to the deck
        }
        if (line===lines.length-1) {
            sectionContent.slides.push(slides);
            sectionContent.pictures.push(pictures);
            chapterContent.sections.push(sectionContent);
            courseContent.push(chapterContent)
        }
    }
    console.log('course Content: ', courseContent);
    return courseContent;
}

function formatSlides(slides) {
    let formattedSlides = [];
    slides.forEach(sl => {
        formattedSlides.push(replaceAll_(sl));
    });
    return formattedSlides;
}

function replaceAll_(sl) {
    let i = sl.replace("_", "<i>").replace("_", "</i>");
    let j = i.replace("**", "<b>").replace("**", "</b>");
    if (j.includes("_") || j.includes("**")) {
        j = replaceAll_(j);
        return j;
    }
    return j;
}

/*
class courseContent extends Component {
    state = {
        courseContent: [],
    }
    componentDidMount() {
        fetch(content)
        .then(res => res.text())
        .then(data => {
            //console.log(data);
            this.loadData(data);
            //fr.read(data);
        })
    }
    
    render() {
        const C = this.state.component;
        return C ? <C {...this.props}/> : null;
    }
    
}
*/
export default md_parser;

//fr.readAsText(content)//'./course_content.md'); // Not sure this will work. 