import React from "react";
import Slide from "./Slide";

class Section extends React.Component {
    render() {
        let { section, sectionIndex, bulletIndex } = this.props;
        let sectionTitle = undefined;
        if (section !== undefined && section.title !== undefined) {
            sectionTitle = section.title.replace(/\**/g, '');
        }
        //section = section.filter((section, ind) => ind == sectionIndex);
        return (
            <div className="sectionContent" key={'div'+sectionTitle}>
                <h1 key={'h1'+sectionTitle}>{sectionTitle}</h1>
                {section.slides !== undefined ? 
                <Slide 
                    slides={section.slides} 
                    sectionIndex={sectionIndex} 
                    slideIndex={bulletIndex} 
                    pictures={section.pictures} />
                : <></>}
            </div>
        )
    }
}
export default Section;