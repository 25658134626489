import React from "react";
import Content from "./Content";
//import courseContent from "./md_parser";

let page = 0;
/*
async function* getContent() {
    //let content = Content;
    let content = courseContent;
    console.log('content is: ', content);
}
*/

export default function OnlineCourse() {
    return (
        <div className="courseContainer">
            <h1 className="courseTitle">Pro Carry USA Online Course</h1>
            <div className="courseContent"><Content /></div>
        </div>
    )
}