import React from 'react';
import logo from './img/logo-jpeg-bk.jpg';
import './App.css';
import ProCarryMenu from './Routes';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p><img className="App-header-logo" src={logo} /></p>
      </header>
      <h1 className="pageTitle">Welcome to Pro Carry USA</h1>
      <ProCarryMenu />
    </div>
  );
}

export default App;
