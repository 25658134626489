import React from "react";
import content from './course_content.md';
import md_parser from './md_parser';
import Chapter from './Chapter';
/* 
Presentation Hierarchy: 

Presentation
[
    {
        "title":"Chapter 1",
        "sections": [
            {
                "title": "Section 1",
                "slides": [
                    "Slide 1 Content", 
                    "Slide 2 Content"
                ],
                "pictures": [
                    "<img src='/link/to/picture.jpg' />"
                ]
            }
        ]
    }
]    


-->Chapter
---->section
------> Section Heading (Use custom tag: <sh></sh>)
------> Bullet Point 
------> [Picture] (Every section has one picture minimum. Start section with the picture, then add a {newpicture} flag on the consumed element to advance the slide)
------> Pagination/Progress Bar
------> Page Turning (Forward by bullet point, back by section)
------> Quiz
    ----> Question
    ----> Answer(s)
    ----> Key
    ----> Submit button scores the chapter. 
    ----> Pass/Fail @ 80%
    ----> Must pass or return to beginning of section. 


Document specifications: 
- Each line break indicates a new bullet point. 
- Tabs can, where applicable, be parsed into paragraph breaks. 
- Document gets parsed by the JS and put into an object similar to below. 

*/

export default class Content extends React.Component {
    constructor(props) {
        super(props);
        /* TODO: Load the state from the server and save the state to the server so students can pick up where they leave off */
        this.state = {
            chapterIndex: 0,
            sectionIndex: 0,
            bulletIndex: 0,
            courseContent: []
        }
    }
    nextChapter = () => {
        let {chapterIndex} = this.state;
        chapterIndex++;
        this.setState({
            chapterIndex: chapterIndex,
            sectionIndex: 0,
            bulletIndex: 0
        });
    }
    nextSection = () => {
        let {courseContent, chapterIndex, sectionIndex} = this.state;
        let chapter_length = courseContent[chapterIndex].sections.length;
        sectionIndex++;
        if (sectionIndex === chapter_length) {
            console.log('going to next chapter');
            this.nextChapter();
        } else {
            console.log(sectionIndex);
            this.setState({
                sectionIndex: sectionIndex++,
                bulletIndex: 0
            })
        }
    }
    nextBullet = () => {
        let {courseContent, bulletIndex, chapterIndex, sectionIndex} = this.state;
        let maxBulletIndex = courseContent[chapterIndex]["sections"][sectionIndex]["slides"].length
        bulletIndex++;
        if (bulletIndex == maxBulletIndex) {
            this.nextSection();
        } else {
            this.setState({
                bulletIndex:bulletIndex++
            });
        }
    }
    prevChapter = () => {
        let {chapterIndex} = this.state;
        if (chapterIndex !== 0) {
            chapterIndex--;
            console.log(chapterIndex);
            this.setState({
                chapterIndex: chapterIndex--,
                sectionIndex: 0,
                bulletIndex: 0
            });
        } 
    }
    prevSection = () => {
        let {courseContent, chapterIndex, sectionIndex} = this.state;
        console.log(courseContent[chapterIndex]);
        //let chapter_length = courseContent[chapterIndex].chapters.length;
        sectionIndex--;
        if (sectionIndex <= 0) {
            console.log('going to Prev chapter', chapterIndex-1);
            sectionIndex = courseContent[chapterIndex].sections.length-1;
            chapterIndex = chapterIndex-1;
            this.setState({
                chapterIndex: chapterIndex--,
                sectionIndex: sectionIndex,
                bulletIndex: 0
            });
        } else {
            console.log(sectionIndex);
            this.setState({
                sectionIndex: sectionIndex--,
                bulletIndex: 0
            })
        }
    }
    prevBullet = () => {
        let {bulletIndex}=this.state;
        bulletIndex++;
        this.setState({
            bulletIndex: bulletIndex++
        })
    }
    componentDidMount() {
        fetch(content)
        .then(res => res.text())
        .then(data => {
            this.setState({
                courseContent: md_parser(data)
            });
        })
    }

    render() {
        const { chapterIndex, sectionIndex, bulletIndex } = this.state;
        let { courseContent } = this.state;
        
        if (courseContent === undefined || courseContent.length < 2) {
            return <div>Loading...</div>
        } else {
            courseContent = courseContent.filter((row, index) => index===chapterIndex );
            /*console.log(courseContent); */
            return(
                <div>
                    {courseContent.map((chapter, ChapterNumber) => {
                        return (
                            <Chapter chapter={chapter} chapterIndex={chapterIndex} sectionIndex={sectionIndex} bulletIndex={bulletIndex} />
                        )
                    })}
                    {bulletIndex!==0 ? <button onClick={this.prevSection}>Back</button> : <></>}
                    <button onClick={this.nextBullet}>Forward</button>
                    {chapterIndex!==0 ? <button onClick={this.prevChapter}>Previous Chapter</button> : <></>}
                    <button onClick={this.nextChapter}>Next Chapter</button>
                </div>
            )
        }
    }
}
