import React from "react";
import {
    BrowserRouter as Router,
    Switch, 
    Route,
    Link
} from "react-router-dom";
//import App from "./App";
import OnlineCourse from "./OnlineCourse/OnlineCourse";

export default function ProCarryMenu() {
    return (
        <Router>
            <div>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/OnlineCourse">Online Course</Link></li>
                </ul>
                <Switch>
                    <Route path="/OnlineCourse/"><OnlineCourse /></Route>
                </Switch>
            </div>
        </Router>
    )
}